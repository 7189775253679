export default function lastAccordion() {
    const accordions = document.getElementsByClassName(
        'single-product-accordion'
    );

    if (accordions.length > 0) {
        const lastAccordion = accordions[accordions.length - 1];

        lastAccordion.classList.add('-last');
    }
}
