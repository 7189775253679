import Swiper, { Navigation, Pagination } from 'swiper';

export default function carousels() {
    Swiper.use([Pagination, Navigation]);

    const sliders = document.querySelectorAll('.js-mobile-slider');

    if (sliders) {
        sliders.forEach(function (slider) {
            const mobileCarusel = new Swiper(slider, {
                slidesPerView: 1.2,
                spaceBetween: 16,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });
        });
    }
    const relatedProducts = document.getElementById('related_products');

    const relatedCarousel = new Swiper(relatedProducts, {
        slidesPerView: 4,
        spaceBetween: 16,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
            768: {
                slidesPerView: 2.5,
            },
            400: {
                slidesPerView: 1.5,
            },
            360: {
                slidesPerView: 1.2,
            },
            300: {
                slidesPerView: 1,
            },
        },
    });
}
