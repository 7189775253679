/**
 * Page Header Sticky block script
 */
export default function pageHeaderSticky() {
    /**
     * shrink header on scroll
     */
    const header = document.getElementById('js-page-header');
    const headerTop = document.getElementById('js-header-top');
    const headerMenu = document.querySelector('.header-menu');
    const headerLink = document.querySelectorAll('.header-menu__link');
    const headerHeight = header.offsetHeight;
    const headerBurger = document.querySelector('.js-burger');
    const headerSearch = document.querySelector('.js_header_search_submit');
    let scrollDirection = null;
    let lastScrollTop = 0;

    document.addEventListener('scroll', event => {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (!header.classList.contains('is-active')) {
            //scroll strength
            if (Math.abs(st - lastScrollTop) < 15) {
                return;
            }

            //scroll direction
            if (st > lastScrollTop) {
                removeDisabled();
            } else {
                removeDisabled();
            }

            //fixed
            if (window.scrollY > headerHeight + 20) {
                if (!header.classList.contains('-other')) {
                    header.classList.add('is-fixed');
                    headerBurger.classList.add('is-scrolled');
                    headerMenu.classList.add('-active');
                    headerTop.classList.add('-hidden');
                    headerLink.forEach(function (e) {
                        e.classList.add('-scroll');
                    });
                    headerSearch.classList.add('-scroll');
                } else {
                    header.classList.add('-scrolled');
                    headerTop.classList.add('-hidden');
                }
            } else if (window.scrollY <= 20) {
                if (!header.classList.contains('-other')) {
                    header.classList.remove('is-fixed');
                    headerBurger.classList.remove('is-scrolled');
                    headerMenu.classList.remove('-active');
                    headerTop.classList.remove('-hidden');
                    headerLink.forEach(function (e) {
                        e.classList.remove('-scroll');
                    });
                    headerSearch.classList.remove('-scroll');
                } else {
                    header.classList.remove('-scrolled');
                    headerTop.classList.remove('-hidden');
                }
            }
        }
        if (window.scrollY <= 20) {
            if (!header.classList.contains('-other')) {
                header.classList.remove('is-fixed', 'is-scrolled', 'is-sticky');
                headerMenu.classList.remove('-active');
                headerTop.classList.remove('-hidden');
            }
        }

        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    });

    function removeDisabled() {
        let timeoutHandle = null;
        if (timeoutHandle) {
            window.clearTimeout(timeoutHandle);
            let timeoutHandle = null;
        }
        header.classList.add('is-disabled');
        timeoutHandle = window.setTimeout(function () {
            header.classList.remove('is-disabled');
        }, 200);
    }
}
