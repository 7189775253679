export default function megaMenu() {
    var $ = jQuery;

    const $megamenuChildrenClass = $('.has-children'),
        $categories = $('.js-change-category'),
        $subcategory = $('.js-change-subcategory'),
        $box = $('.js-box'),
        $submenu = $('.header-menu__submenu'),
        $images = $('.js-change-image'),
        $menuItems = $('.header-menu__item');

    $categories.each(function () {
        $(this).hover(function () {
            $categories.removeClass('-active');

            $(this).addClass('-active');

            const $cat = '.' + $(this).data('category');

            $subcategory.removeClass('-opacity');

            $($cat).addClass('-opacity');

            $images.addClass('-hide');

            $('.js-images').find($cat).removeClass('-hide');
        });
    });

    $menuItems.on('mouseenter', function (){
        $megamenuChildrenClass.removeClass('-active');
    });

    $megamenuChildrenClass.on('mouseenter', function () {
        $megamenuChildrenClass.removeClass('-active');
        $(this).addClass('-active');
        $('.js-dropdown').removeClass('-active');
        $(this).children('.js-dropdown').addClass('-active');
        $categories.first().addClass('-active');
        if ($categories.first().hasClass('-active')) {
            $subcategory.first().addClass('-opacity');
        }
    });

    $box.on('mouseleave', function () {
        $megamenuChildrenClass.removeClass('-active');
        $(this).parent().parent().removeClass('-active');
        $subcategory.removeClass('-opacity');
        $categories.removeClass('-active');
    });

    $submenu.on('mouseleave', function () {
        $megamenuChildrenClass.removeClass('-active');
    });

    // $(document).on('scroll', function(){
    //     $('.js-dropdown').removeClass('-active');
    // });
}
